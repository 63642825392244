const sortByBrand = (sparesArr, isSorted, setIsSorted, setSearchState) => {
  if (isSorted) {
    setIsSorted(false)
    const sorted = sparesArr.toSorted((a, b) => {
      if (a.brand < b.brand) {
        return 1
      } else {
        return -1
      }
    })
    setSearchState(sorted)
    return
  }
  const sorted = sparesArr.toSorted((a, b) => {
    if (a.brand > b.brand) {
      return 1
    } else {
      return -1
    }
  })
  setIsSorted(true)
  setSearchState(sorted)
}

const sortByCode = (sparesArr, isSorted, setIsSorted, setSearchState) => {
  if (isSorted) {
    setIsSorted(false)
    const sorted = sparesArr.toSorted((a, b) => {
      if (a.code < b.code) {
        return 1
      } else {
        return -1
      }
    })
    setSearchState(sorted)
    return
  }
  const sorted = sparesArr.toSorted((a, b) => {
    if (a.code > b.code) {
      return 1
    } else {
      return -1
    }
  })
  setIsSorted(true)
  setSearchState(sorted)
}

const sortByDesc = (sparesArr, isSorted, setIsSorted, setSearchState) => {
  if (isSorted) {
    setIsSorted(false)
    const sorted = sparesArr.toSorted((a, b) => {
      if (a.description < b.description) {
        return 1
      } else {
        return -1
      }
    })
    setSearchState(sorted)
    return
  }
  const sorted = sparesArr.toSorted((a, b) => {
    if (a.description > b.description) {
      return 1
    } else {
      return -1
    }
  })
  setIsSorted(true)
  setSearchState(sorted)
}

const sortByRetailPrice = (
  sparesArr,
  isSorted,
  setIsSorted,
  setSearchState
) => {
  if (isSorted) {
    setIsSorted(false)
    const sorted = sparesArr.toSorted((a, b) => {
      if (a.retailPrice < b.retailPrice) {
        return 1
      } else {
        return -1
      }
    })
    setSearchState(sorted)
    return
  }
  const sorted = sparesArr.toSorted((a, b) => {
    if (a.retailPrice > b.retailPrice) {
      return 1
    } else {
      return -1
    }
  })
  setIsSorted(true)
  setSearchState(sorted)
}

const sortByPrice = (sparesArr, isSorted, setIsSorted, setSearchState) => {
  if (isSorted) {
    setIsSorted(false)
    const sorted = sparesArr.toSorted((a, b) => {
      if (a.price < b.price) {
        return 1
      } else {
        return -1
      }
    })
    setSearchState(sorted)
    return
  }
  const sorted = sparesArr.toSorted((a, b) => {
    if (a.price > b.price) {
      return 1
    } else {
      return -1
    }
  })
  setIsSorted(true)
  setSearchState(sorted)
}

const sortByAvailability = (
  sparesArr,
  isSorted,
  setIsSorted,
  setSearchState
) => {
  if (isSorted) {
    setIsSorted(false)
    const sorted = sparesArr.toSorted((a, b) => {
      if (a.availability < b.availability) {
        return 1
      } else {
        return -1
      }
    })
    setSearchState(sorted)
    return
  }
  const sorted = sparesArr.toSorted((a, b) => {
    if (a.availability > b.availability) {
      return 1
    } else {
      return -1
    }
  })
  setIsSorted(true)
  setSearchState(sorted)
}
const sortByDeliveryTime = (
  sparesArr,
  isSorted,
  setIsSorted,
  setSearchState
) => {
  if (isSorted) {
    setIsSorted(false)
    const sorted = sparesArr.toSorted((a, b) => {
      if (a.deliveryTime < b.deliveryTime) {
        return 1
      } else {
        return -1
      }
    })
    setSearchState(sorted)
    return
  }
  const sorted = sparesArr.toSorted((a, b) => {
    if (a.deliveryTime > b.deliveryTime) {
      return 1
    } else {
      return -1
    }
  })
  setIsSorted(true)
  setSearchState(sorted)
}

const handleSortBy = (
  sparesArr,
  sortBy,
  isSorted,
  setIsSorted,
  setSearchState
) => {
  if (sortBy === 'brand') {
    sortByBrand(sparesArr, isSorted, setIsSorted, setSearchState)
  }
  if (sortBy === 'code') {
    sortByCode(sparesArr, isSorted, setIsSorted, setSearchState)
  }
  if (sortBy === 'description') {
    sortByDesc(sparesArr, isSorted, setIsSorted, setSearchState)
  }
  if (sortBy === 'retailPrice') {
    sortByRetailPrice(sparesArr, isSorted, setIsSorted, setSearchState)
  }
  if (sortBy === 'price') {
    sortByPrice(sparesArr, isSorted, setIsSorted, setSearchState)
  }
  if (sortBy === 'availability') {
    sortByAvailability(sparesArr, isSorted, setIsSorted, setSearchState)
  }
  if (sortBy === 'deliveryTime') {
    sortByDeliveryTime(sparesArr, isSorted, setIsSorted, setSearchState)
  }
}

export default handleSortBy

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setLogout } from '../redux/auth/authSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  credentials: 'include',
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

const baseQueryReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 403) {
    const refreshResult = await baseQuery(
      '/client/token/refresh',
      api,
      extraOptions
    )
    if (refreshResult?.data) {
      localStorage.setItem('accessToken', refreshResult.data.accessToken)

      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(setLogout())
    }
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({}),
})

import { apiSlice } from '../../api/apiSlice'

const apiRegistre = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendPhoneNumber: builder.mutation({
      query: (body) => ({
        url: `/phone`,
        method: 'POST',
        body,
      }),
    }),
    sendVerificationCode: builder.mutation({
      query: (body) => ({
        url: `/phone/verify`,
        method: 'POST',
        body,
      }),
    }),
    getPhoneNumber: builder.query({
      query: () => `/phone/get`,
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: `/client/create`,
        method: 'POST',
        body,
      }),
    }),
    checkPhoneExistance: builder.mutation({
      query: (body) => ({
        url: `/phone/check`,
        method: 'POST',
        body,
      }),
    }),
    passwordReset: builder.mutation({
      query: (body) => ({
        url: `/client/reset/password`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useSendPhoneNumberMutation,
  useSendVerificationCodeMutation,
  useGetPhoneNumberQuery,
  useCreateUserMutation,
  useCheckPhoneExistanceMutation,
  usePasswordResetMutation,
} = apiRegistre

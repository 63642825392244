import { useSelector } from 'react-redux'
import { selectAccessToken } from '../redux/auth/authSlice'
import { Navigate, Outlet } from 'react-router-dom'

const RequireAuth = () => {
  const accessToken = useSelector(selectAccessToken)

  return <>{accessToken ? <Outlet /> : <Navigate to="/login" />} </>
}

export default RequireAuth

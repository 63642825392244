import { Link } from 'react-router-dom'
import styles from './NotFoundPage.module.css'

const NotFoundPage = () => {
  return (
    <div className="page">
      <div className={styles.notFoundContainer}>
        <div className={styles.notFoundHeader}>404</div>
        <div className={styles.notFoundTextContainer}>
          За вашим запитом нічого не знайдено...
        </div>
        <div className={styles.returnLinkContainer}>
          <Link className={styles.returnLink} to="/">
            На головну сторінку
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage

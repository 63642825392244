import { DateTime } from 'luxon'

function getCurrentTime(minutes = 0) {
  if (minutes) {
    const dt = DateTime.now().plus({ minutes })
    const time = dt.toFormat('hh:mm')
    return time
  }
  const dt = DateTime.now()
  const time = dt.toFormat('hh:mm')
  return time
}
export default getCurrentTime

import React, { useState } from 'react'
import styles from './ModalImage.module.css'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { IoIosArrowDropleftCircle } from 'react-icons/io'
import { IoCloseCircleOutline } from 'react-icons/io5'

const ModalImage = ({ imageToOpen, setImageToOpen }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageToOpen.length - 1 : prevIndex - 1
    )
  }

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageToOpen.length - 1 ? 0 : prevIndex + 1
    )
  }

  const handleDotClick = (index) => {
    setCurrentIndex(index)
  }

  return (
    <div className="overlayCont">
      <div className={styles.contentContainer}>
        <div className={styles.modalContainer}>
          {imageToOpen.length > 1 && (
            <button onClick={handlePrevClick} className={styles.arrowButton}>
              <IoIosArrowDropleftCircle />
            </button>
          )}
          <div className={styles.imageContainer}>
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${imageToOpen[currentIndex].photo_url}`}
              alt={`Image ${currentIndex + 1}`}
              className={styles.modalImage}
            />
            <IoCloseCircleOutline
              className={styles.closeIcon}
              onClick={() => setImageToOpen(null)}
            />
          </div>
          {imageToOpen.length > 1 && (
            <button onClick={handleNextClick} className={styles.arrowButton}>
              <IoIosArrowDroprightCircle />
            </button>
          )}
        </div>
        {imageToOpen.length > 1 && (
          <div className={styles.dotsContainer}>
            {imageToOpen.map((_, index) => (
              <span
                key={index}
                className={`${styles.dot} ${
                  currentIndex === index ? styles.activeDot : ''
                }`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ModalImage

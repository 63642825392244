import logoIcon from '../../img/logoIcon.png'
import styles from './Loader.module.css'

const Loader = () => {
  return (
    <div className="overlayCont">
      <div>
        <div className={styles.loader}>
          <img className={styles.logoIcon} src={logoIcon} alt="logoIcon" />
        </div>
        <div className={styles.loaderText}>Зачекайте...</div>
      </div>
    </div>
  )
}

export default Loader

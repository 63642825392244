import React, { useState, useRef } from 'react'
import { GrReturn } from 'react-icons/gr'
import styles from '../../pages/resetPassword/ResetPasswordPage.module.css'
import {
  useSendPhoneNumberMutation,
  useSendVerificationCodeMutation,
} from '../../redux/registre/apiRegistre'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectFullTimeLeftForResend,
  setCodeSentResetPassword,
  setCodeVerifiedResetPassword,
  setFullTimeLeftForResend,
  setTimeLeftForResend,
} from '../../redux/registre/registreSlice'
import getCurrentTime from '../../utils/getCurrentTime'
import { toast } from 'react-toastify'
import Viber from '../viber/Viber'
import Loader from '../loader/Loader'

const PhoneVerification = () => {
  const [digit1, setDigit1] = useState('')
  const [digit2, setDigit2] = useState('')
  const [digit3, setDigit3] = useState('')
  const [digit4, setDigit4] = useState('')

  const refInput2 = useRef(null)
  const refInput3 = useRef(null)
  const refInput4 = useRef(null)

  const fullTimeLeftForResend = useSelector(selectFullTimeLeftForResend)
  const dispatch = useDispatch()

  const [sendPhoneNumber, { isLoading: isLoadingSendPhoneNumber }] =
    useSendPhoneNumberMutation()
  const [sendVerificationCode, { isLoading: isLoadingSendVerificationCode }] =
    useSendVerificationCodeMutation()

  const returnToPreviousPage = () => {
    dispatch(setCodeSentResetPassword(false))
  }

  const handleInputChange = (setDigitFunction, nextInputRef, value) => {
    if (value.length <= 1) {
      setDigitFunction(value)
      if (value.length === 1 && nextInputRef) {
        nextInputRef.current.focus()
      }
    }
  }

  const resendVerificationCode = async () => {
    if (fullTimeLeftForResend <= new Date()) {
      try {
        const result = await sendPhoneNumber().unwrap()
        if (result.codeSent) {
          dispatch(setTimeLeftForResend(getCurrentTime(5)))
          dispatch(
            setFullTimeLeftForResend(
              new Date().setMinutes(new Date().getMinutes() + 4)
            )
          )
        }
      } catch (error) {
        toast.error('Сталась помилка...')
      }
    } else {
      toast.error(`Отримати новий код можна через 5хв`)
    }
  }
  const handleSubmitPhoneForm = async (e) => {
    e.preventDefault()
    const verificationCode = digit1 + digit2 + digit3 + digit4
    try {
      const apiResponse = await sendVerificationCode({
        verificationCode,
      }).unwrap()
      if (apiResponse.verified) {
        dispatch(setCodeVerifiedResetPassword(true))
      }
    } catch (error) {
      if (
        error.status === 400 &&
        error.data.message === 'Invalid verification code'
      ) {
        toast.error('Не вірний код підтвердження')
        setDigit1('')
        setDigit2('')
        setDigit3('')
        setDigit4('')
      } else if (
        error.status === 400 &&
        error.data.message === 'Phone number is expired'
      ) {
        toast.error('Номер телефону застарілий')
        dispatch(setCodeSentResetPassword(false))
      } else {
        toast.error('Сталась помилка...')
      }
    }
  }

  return (
    <>
      {isLoadingSendPhoneNumber && <Loader />}
      {isLoadingSendVerificationCode && <Loader />}

      <form
        className={styles.resetPasswordContainer}
        onSubmit={handleSubmitPhoneForm}
      >
        <div className={styles.resetPasswordTextContainer}>
          <h2>Відновлення пароля</h2>
          <p className={styles.secondaryText}>
            Будь ласка, внесіть ваш код підтвердження.
          </p>
        </div>
        <div className={styles.resetPasswordForm}>
          <div
            onClick={returnToPreviousPage}
            className={styles.overlayTextReturn}
          >
            <GrReturn />
            <h3>Назад</h3>
          </div>
          <label className={styles.newCodeText}>Код підтвердження:</label>
          <div className={styles.inputVerificationContainer}>
            <input
              type="text"
              maxLength="1"
              value={digit1}
              onChange={(e) =>
                handleInputChange(setDigit1, refInput2, e.target.value)
              }
              autoFocus
            />
            <input
              type="text"
              maxLength="1"
              ref={refInput2}
              value={digit2}
              onChange={(e) =>
                handleInputChange(setDigit2, refInput3, e.target.value)
              }
            />
            <input
              type="text"
              maxLength="1"
              ref={refInput3}
              value={digit3}
              onChange={(e) =>
                handleInputChange(setDigit3, refInput4, e.target.value)
              }
            />
            <input
              type="text"
              maxLength="1"
              ref={refInput4}
              value={digit4}
              onChange={(e) =>
                handleInputChange(setDigit4, null, e.target.value)
              }
            />
          </div>
          <div className={styles.resendCodeCont}>
            <button
              type="button"
              onClick={resendVerificationCode}
              className={styles.verifyCodeBtn}
            >
              Новий код
            </button>
            <div className={styles.newCodeText}>
              Отримати новий код можна через 5хв
            </div>
          </div>
          <div className={styles.btnContainer}>
            <button
              disabled={
                digit1.length !== 1 ||
                digit2.length !== 1 ||
                digit3.length !== 1 ||
                digit4.length !== 1
              }
              className={
                digit1.length !== 1 ||
                digit2.length !== 1 ||
                digit3.length !== 1 ||
                digit4.length !== 1
                  ? styles.resetPasswordBtnDisabled
                  : styles.resetPasswordBtn
              }
              type="submit"
            >
              Підтвердити код
            </button>
          </div>
        </div>
        <Viber />
      </form>
    </>
  )
}

export default PhoneVerification

import { useState } from 'react'
import { GrReturn } from 'react-icons/gr'
import styles from '../../pages/registre/RegistrePage.module.css'
import { useDispatch } from 'react-redux'
import {
  setClientType,
  setCodeIsVerified,
  setCodeSent,
} from '../../redux/registre/registreSlice'
import { toast } from 'react-toastify'
import Viber from '../viber/Viber'

const FormClientType = () => {
  const [isCarOwner, setIsCarOwner] = useState(false)
  const [isSparesShop, setIsSparesShop] = useState(false)
  const [isInternetShop, setIsInternetShop] = useState(false)
  const [isServiceStation, setIsServiceStation] = useState(false)

  const handleSelectWorkType = (workType, setWorkType) => {
    setIsCarOwner(false)
    setIsSparesShop(false)
    setIsInternetShop(false)
    setIsServiceStation(false)
    setWorkType(!workType)
  }

  const dispatch = useDispatch()

  const returnToPreviousPage = () => {
    dispatch(setCodeIsVerified(false))
    dispatch(setCodeSent(false))
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (isCarOwner || isSparesShop || isInternetShop || isServiceStation) {
      if (isCarOwner) {
        dispatch(setClientType(1))
      } else if (isSparesShop) {
        dispatch(setClientType(2))
      } else if (isInternetShop) {
        dispatch(setClientType(3))
      } else if (isServiceStation) {
        dispatch(setClientType(4))
      }
    } else {
      toast.error('Оберіть тип діяльності')
    }
  }

  return (
    <>
      <form
        className={styles.registreFormContainer}
        onSubmit={handleSubmitForm}
      >
        <div className={styles.registreTextContainer}>
          <h2>Реєстрація</h2>
          <p className={styles.secondaryText}>
            Будь ласка, оберіть ваш тип діяльності.
          </p>
        </div>
        <div className={styles.registreForm}>
          <div
            onClick={returnToPreviousPage}
            className={styles.overlayTextReturn}
          >
            <GrReturn />
            <h3>Назад</h3>
          </div>
          <label className={styles.secondaryText}>Тип діяльності:</label>
          <div className={styles.workTypeContainer}>
            <button
              type="button"
              className={
                isCarOwner ? styles.workTypeBtnSelected : styles.workTypeBtn
              }
              onClick={() => handleSelectWorkType(isCarOwner, setIsCarOwner)}
            >
              Роздрібний покупець
            </button>
            <button
              onClick={() =>
                handleSelectWorkType(isSparesShop, setIsSparesShop)
              }
              type="button"
              className={
                isSparesShop ? styles.workTypeBtnSelected : styles.workTypeBtn
              }
            >
              Автомагазин
            </button>
            <button
              onClick={() =>
                handleSelectWorkType(isInternetShop, setIsInternetShop)
              }
              type="button"
              className={
                isInternetShop ? styles.workTypeBtnSelected : styles.workTypeBtn
              }
            >
              Інтернет-магазин
            </button>
            <button
              onClick={() =>
                handleSelectWorkType(isServiceStation, setIsServiceStation)
              }
              type="button"
              className={
                isServiceStation
                  ? styles.workTypeBtnSelected
                  : styles.workTypeBtn
              }
            >
              СТО
            </button>
          </div>
          <div className={styles.btnContainer}>
            <button
              disabled={
                !isCarOwner &&
                !isSparesShop &&
                !isInternetShop &&
                !isServiceStation
              }
              className={
                !isCarOwner &&
                !isSparesShop &&
                !isInternetShop &&
                !isServiceStation
                  ? styles.registreBtnDisabled
                  : styles.registreBtn
              }
              type="submit"
            >
              Далі
            </button>
          </div>
        </div>
        <Viber />
      </form>
    </>
  )
}

export default FormClientType

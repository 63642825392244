import Loader from '../../components/loader/Loader'
import { useGetClientDataQuery } from '../../redux/clientData/apiClientData'
import { toast } from 'react-toastify'
import styles from './Home.module.css'
import { useLogoutMutation } from '../../redux/auth/apiAuth'
import { setLogout } from '../../redux/auth/authSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import Viber from '../../components/viber/Viber'
import Search from '../../components/home/search/Search'
import Info from '../../components/home/info/Info'
import PaymentPlan from '../../components/home/paymentPlan/PaymentPlan'
import SearchHistory from '../../components/home/searchHistory/SearchHistory'
import { useGetCurrencyRatesQuery } from '../../redux/data/apiData'

const HomePage = () => {
  const [searchBtnSelected, setSearchBtnSelected] = useState(true)
  const [infoBtnSelected, setInfoBtnSelected] = useState(false)
  const [searchHistoryBtnSelected, setSearchHistoryBtnSelected] =
    useState(false)
  const [paymentPlanBtnSelected, setPaymentPlanBtnSelected] = useState(false)

  const { data: clientData, isLoading: isLoadingClientData } =
    useGetClientDataQuery()

  const { data: currencyRates, isLoading: isLoadingCurrencyRates } =
    useGetCurrencyRatesQuery()

  const [logout, { isLoading: isLoadingLogout }] = useLogoutMutation()

  const dispatch = useDispatch()

  const handleLogout = async () => {
    try {
      await logout()
      dispatch(setLogout())
      window.location.reload()
    } catch (error) {
      toast.error('Сталась помилка...')
    }
  }

  const handleNavbarBtnClick = (e) => {
    const btnName = e.target.innerText
    if (btnName === 'Пошук') {
      setSearchBtnSelected(true)
      setInfoBtnSelected(false)
      setSearchHistoryBtnSelected(false)
      setPaymentPlanBtnSelected(false)
    } else if (btnName === 'Інфо') {
      setSearchBtnSelected(false)
      setInfoBtnSelected(true)
      setSearchHistoryBtnSelected(false)
      setPaymentPlanBtnSelected(false)
    } else if (btnName === 'Історія переглядів') {
      setSearchBtnSelected(false)
      setInfoBtnSelected(false)
      setSearchHistoryBtnSelected(true)
      setPaymentPlanBtnSelected(false)
    } else if (btnName === 'План оплат') {
      setSearchBtnSelected(false)
      setInfoBtnSelected(false)
      setSearchHistoryBtnSelected(false)
      setPaymentPlanBtnSelected(true)
    }
  }

  return (
    <div className="page">
      {isLoadingClientData && <Loader />}
      {isLoadingLogout && <Loader />}
      {isLoadingCurrencyRates && <Loader />}
      <div className={styles.homePageContainer}>
        <div className={styles.topContainer}>
          <div className={styles.clientCodeContainer}>
            <h3 className={styles.clientCodeText}>
              {clientData?.clientCode}
              <span onClick={handleLogout} className={styles.logOutText}>
                [ Вихід ]
              </span>
            </h3>
          </div>
          <div>
            <h2 className={styles.companyName}>СПРИНТ</h2>
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.clientDataContainer}>
            <div className={styles.nameContainer}>{clientData?.firstName}</div>
            <div className={styles.moneyBalanceContainer}>
              <span className={styles.balanceTextContainer}>
                {clientData?.balance > 0 ? 'Передплата: ' : 'Борг: '}
              </span>
              {Math.abs(clientData?.balance)}
            </div>
            <div className={styles.currencyRateContainer}>
              <div>
                <span className={styles.currencyTextContainer}>USD:</span>
                <span>{currencyRates?.usd}</span>
              </div>
              <div>
                <span className={styles.currencyTextContainer}>EUR:</span>
                <span>{currencyRates?.eur}</span>
              </div>
            </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.menu}>
              <button
                onClick={handleNavbarBtnClick}
                className={
                  searchBtnSelected ? styles.menuBtnSelected : styles.menuBtn
                }
              >
                Пошук
              </button>
              <button
                onClick={handleNavbarBtnClick}
                className={
                  infoBtnSelected ? styles.menuBtnSelected : styles.menuBtn
                }
              >
                Інфо
              </button>
              <button
                onClick={handleNavbarBtnClick}
                className={
                  searchHistoryBtnSelected
                    ? styles.menuBtnSelected
                    : styles.menuBtn
                }
              >
                Історія переглядів
              </button>
              <button
                onClick={handleNavbarBtnClick}
                className={
                  paymentPlanBtnSelected
                    ? styles.menuBtnSelected
                    : styles.menuBtn
                }
              >
                План оплат
              </button>
            </div>
            {searchBtnSelected && (
              <Search searchBtnSelected={searchBtnSelected} />
            )}
            {infoBtnSelected && <Info rerender={infoBtnSelected} />}
            {paymentPlanBtnSelected && (
              <PaymentPlan rerender={paymentPlanBtnSelected} />
            )}
            {searchHistoryBtnSelected && (
              <SearchHistory rerender={searchHistoryBtnSelected} />
            )}
          </div>
          <div className={styles.viberContainer}>
            <Viber />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isCodeSent: false,
  isCodeSentResetPassword: false,
  timeLeftForResend: 0,
  fullTimeLeftForResend: 0,
  isCodeVerified: false,
  isCodeVerifiedResetPassword: false,
  clientType: null,
}

const registreSlice = createSlice({
  name: 'registreSlice',
  initialState,
  reducers: {
    setCodeSent: (state, action) => {
      state.isCodeSent = action.payload
      localStorage.setItem('isCodeSent', JSON.stringify(action.payload))
    },
    setCodeSentResetPassword: (state, action) => {
      state.isCodeSentResetPassword = action.payload
      localStorage.setItem(
        'isCodeSentResetPassword',
        JSON.stringify(action.payload)
      )
    },
    setCodeIsVerified: (state, action) => {
      state.isCodeVerified = action.payload
      localStorage.setItem('isCodeVerified', JSON.stringify(action.payload))
    },
    setCodeVerifiedResetPassword: (state, action) => {
      state.isCodeVerifiedResetPassword = action.payload
      localStorage.setItem(
        'isCodeVerifiedResetPassword',
        JSON.stringify(action.payload)
      )
    },
    setTimeLeftForResend: (state, action) => {
      state.timeLeftForResend = action.payload
      localStorage.setItem('timeLeftForResend', JSON.stringify(action.payload))
    },
    setFullTimeLeftForResend: (state, action) => {
      state.fullTimeLeftForResend = action.payload
      localStorage.setItem(
        'fullTimeLeftForResend',
        JSON.stringify(action.payload)
      )
    },
    setClientType: (state, action) => {
      state.clientType = action.payload
      localStorage.setItem('clientType', JSON.stringify(action.payload))
    },
  },
})

export const {
  setCodeSent,
  setTimeLeftForResend,
  setCodeIsVerified,
  setClientType,
  setFullTimeLeftForResend,
  setCodeSentResetPassword,
  setCodeVerifiedResetPassword,
} = registreSlice.actions

export const selectIsCodeSent = (state) => state.registreSlice.isCodeSent
export const selectTimeLeftForResend = (state) =>
  state.registreSlice.timeLeftForResend
export const selectIsCodeVerified = (state) =>
  state.registreSlice.isCodeVerified
export const selectClientType = (state) => state.registreSlice.clientType
export const selectFullTimeLeftForResend = (state) =>
  state.registreSlice.fullTimeLeftForResend
export const selectIsCodeSentResetPassword = (state) =>
  state.registreSlice.isCodeSentResetPassword
export const selectIsCodeVerifiedResetPassword = (state) =>
  state.registreSlice.isCodeVerifiedResetPassword

export default registreSlice.reducer

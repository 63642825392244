import { BsFillEyeSlashFill } from 'react-icons/bs'
import { FaEye } from 'react-icons/fa'
import styles from '../../pages/registre/RegistrePage.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { GrReturn } from 'react-icons/gr'
import { useState } from 'react'
import {
  useCreateUserMutation,
  useGetPhoneNumberQuery,
} from '../../redux/registre/apiRegistre'
import { toast } from 'react-toastify'
import {
  selectClientType,
  setClientType,
  setCodeIsVerified,
  setCodeSent,
} from '../../redux/registre/registreSlice'
import { useDispatch, useSelector } from 'react-redux'
import Viber from '../viber/Viber'
import Loader from '../loader/Loader'

const FormRetailClient = () => {
  const [password, setPassword] = useState('')
  const [passwordSecond, setPasswordSecond] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordSecondVisible, setIsPasswordSecondVisible] = useState(false)

  const clientType = useSelector(selectClientType)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { data: phoneNumberObj, isLoading: isGetPhoneNumberLoading } =
    useGetPhoneNumberQuery()
  const [createUser, { isLoading: isCreateUserLoading }] =
    useCreateUserMutation()

  const handleReturnToPreviousPage = () => {
    dispatch(setClientType(null))
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    if (password === passwordSecond) {
      try {
        const result = await createUser({
          password,
          phoneNumber: phoneNumberObj.phoneNumber,
          clientTypeID: clientType,
        }).unwrap()
        if (result?.created) {
          dispatch(setCodeIsVerified(false))
          dispatch(setClientType(null))
          dispatch(setCodeSent(false))
          navigate('/login')
        } else {
          toast.error('Сталась помилка...')
        }
      } catch (error) {
        if (error.data.message === 'Phone number is expired') {
          toast.error('Застарілий номер телефону')
          dispatch(setCodeIsVerified(false))
          dispatch(setClientType(null))
          dispatch(setCodeSent(false))
        } else {
          toast.error('Сталась помилка...')
        }
      }
    } else {
      toast.error('Паролі не співпадають')
    }
  }
  return (
    <>
      {isGetPhoneNumberLoading && <Loader />}
      {isCreateUserLoading && <Loader />}
      <form
        className={styles.registreFormContainer}
        onSubmit={handleSubmitForm}
      >
        <div className={styles.registreTextContainer}>
          <h2>Реєстрація</h2>
          <p className={styles.secondaryText}>Будь ласка, придумайте пароль.</p>
        </div>
        <div className={styles.registreForm}>
          <div
            onClick={handleReturnToPreviousPage}
            className={styles.overlayTextReturn}
          >
            <GrReturn />
            <h3>Назад</h3>
          </div>
          <div className={styles.readOnlyInputContainer}>
            <label className={styles.labelForm}>Логін:</label>
            <input
              required
              readOnly
              type="text"
              value={phoneNumberObj?.phoneNumber || ''}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelForm}>Пароль (від 6 символів):</label>
            {isPasswordVisible ? (
              <BsFillEyeSlashFill
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className={styles.eyeIcon}
              />
            ) : (
              <FaEye
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className={styles.eyeIcon}
              />
            )}
            <input
              minLength={6}
              maxLength={50}
              required
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelForm}>Пароль ще раз:</label>
            {isPasswordSecondVisible ? (
              <BsFillEyeSlashFill
                onClick={() =>
                  setIsPasswordSecondVisible(!isPasswordSecondVisible)
                }
                className={styles.eyeIcon}
              />
            ) : (
              <FaEye
                onClick={() =>
                  setIsPasswordSecondVisible(!isPasswordSecondVisible)
                }
                className={styles.eyeIcon}
              />
            )}
            <input
              minLength={6}
              maxLength={50}
              required
              type={isPasswordSecondVisible ? 'text' : 'password'}
              value={passwordSecond}
              onChange={(e) => setPasswordSecond(e.target.value)}
            />
          </div>
          <div className={styles.registreTextCont}>
            <Link className={styles.registreText} to="/login">
              Є обліковий запис?
            </Link>
          </div>
          <div className={styles.btnContainer}>
            <button className={styles.registreBtn} type="submit">
              Зареєструватись
            </button>
          </div>
        </div>
        <Viber />
      </form>
    </>
  )
}

export default FormRetailClient

import React, { useState, useEffect } from 'react'
import { RiFileExcel2Line } from 'react-icons/ri'
import { GrDocumentPdf } from 'react-icons/gr'
import { FaEye } from 'react-icons/fa'
import { ImArrowRight } from 'react-icons/im'
import { ImArrowLeft } from 'react-icons/im'
import { ImCross } from 'react-icons/im'
import styles from './ModalOrdersHistory.module.css'
import {
  useGetClientOrderDocMutation,
  useGetClientOrderDocPdfMutation,
  useGetClientOrderDocXlsxMutation,
  useGetClientOrderHistoryQuery,
} from '../../../redux/clientData/apiClientData'
import Loader from '../../loader/Loader'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'

const ModalOrdersHistory = ({ setIsShowOrdersHistory }) => {
  const { data: ordersHistory, isLoading: isLoadingOrdersHistory } =
    useGetClientOrderHistoryQuery()

  const [getOrderDoc, { isLoading: isLoadingOrderDoc }] =
    useGetClientOrderDocMutation()

  const [getOrderDocPdf, { isLoading: isLoadingOrderDocPdf }] =
    useGetClientOrderDocPdfMutation()

  const [getOrderDocXlsx, { isLoading: isLoadingOrderDocXlsx }] =
    useGetClientOrderDocXlsxMutation()

  const [currentPage, setCurrentPage] = useState(1)
  const [isShowDoc, setIsShowDoc] = useState(false)
  const [docID, setDocID] = useState(null)
  const [docData, setDocData] = useState(null)
  const [docSum, setDocSum] = useState(0)

  const [isShowDetails, setIsShowDetails] = useState(false)
  const [detailedHistory, setDetailedHistory] = useState([])
  const [detailsCurrentPage, setDetailsCurrentPage] = useState(1)

  const [searchTerm, setSearchTerm] = useState('')
  const [detailsSearchTerm, setDetailsSearchTerm] = useState('')

  const itemsPerPage = 10
  const sparesPerPage = 10

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const safeToString = (value) => (value != null ? value.toString() : '')

  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm])

  useEffect(() => {
    setDetailsCurrentPage(1)
  }, [detailsSearchTerm])

  const filteredOrdersHistory = ordersHistory?.orderHistory.filter((order) =>
    Object.values(order).some((val) =>
      safeToString(val).toLowerCase().includes(searchTerm.toLowerCase())
    )
  )

  const totalItems = filteredOrdersHistory?.length || 0
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const lastItemIndex = currentPage * itemsPerPage
  const firstItemIndex = lastItemIndex - itemsPerPage
  const currentItems = filteredOrdersHistory?.slice(
    firstItemIndex,
    lastItemIndex
  )

  const filteredDetailedHistory = detailedHistory.filter((order) => {
    const searchString = detailsSearchTerm.toLowerCase()
    return (
      safeToString(order.docID).toLowerCase().includes(searchString) ||
      safeToString(order.isExternalOrder ? 'Під замовлення' : 'Наявність')
        .toLowerCase()
        .includes(searchString) ||
      safeToString(order.brand).toLowerCase().includes(searchString) ||
      safeToString(order.spareCode).toLowerCase().includes(searchString) ||
      safeToString(order.description).toLowerCase().includes(searchString) ||
      safeToString(parseFloat(order.price).toFixed(2))
        .toLowerCase()
        .includes(searchString) ||
      safeToString(order.qty).toLowerCase().includes(searchString) ||
      safeToString((parseFloat(order.price) * order.qty).toFixed(2))
        .toLowerCase()
        .includes(searchString) ||
      safeToString(
        DateTime.fromJSDate(new Date(order.mvDate)).toFormat('dd.MM.yyyy')
      )
        .toLowerCase()
        .includes(searchString)
    )
  })

  const totalDetailsItems = filteredDetailedHistory.length
  const totalDetailsPages = Math.ceil(totalDetailsItems / sparesPerPage)

  const detailsLastItemIndex = detailsCurrentPage * sparesPerPage
  const detailsFirstItemIndex = detailsLastItemIndex - sparesPerPage
  const currentDetailsItems = filteredDetailedHistory.slice(
    detailsFirstItemIndex,
    detailsLastItemIndex
  )

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const goToNextDetailsPage = () => {
    setDetailsCurrentPage((prevPage) =>
      Math.min(prevPage + 1, totalDetailsPages)
    )
  }

  const goToPreviousDetailsPage = () => {
    setDetailsCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleShowOrderDoc = async (docID, docSum) => {
    const orderDoc = await getOrderDoc({ docID })
    setDocData(orderDoc.data)
    setDocID(docID)
    setDocSum(docSum)
    setIsShowDoc(true)
  }

  const handleReturn = () => {
    setDocData(null)
    setDocID(null)
    setDocSum(0)
    setIsShowDoc(false)
  }

  const handleExportPdf = async (docID) => {
    try {
      await getOrderDocPdf({ docID })
    } catch (error) {
      toast.error('Помилка при експорті')
    }
  }

  const handleExportXlsx = async (docID) => {
    try {
      await getOrderDocXlsx({ docID })
    } catch (error) {
      toast.error('Помилка при експорті')
    }
  }

  const handleShowDetailedHistory = async () => {
    if (!isShowDetails) {
      const detailsPromises = ordersHistory?.orderHistory.map((order) =>
        getOrderDoc({ docID: order.docID })
      )
      const detailsResults = await Promise.all(detailsPromises)
      const detailedHistoryData = detailsResults
        .flatMap((result, index) =>
          result.data.orderDoc.map((doc) => ({
            ...doc,
            docID: ordersHistory.orderHistory[index].docID,
          }))
        )
        .sort((a, b) => {
          return new Date(b.mvDate) - new Date(a.mvDate)
        })
      setDetailedHistory(detailedHistoryData)
      setDetailsCurrentPage(1)
    } else {
      setDetailedHistory([])
    }
    setIsShowDetails(!isShowDetails)
  }

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return text
    }
    const escapedHighlight = escapeRegExp(highlight)
    const regex = new RegExp(`(${escapedHighlight})`, 'gi')
    return safeToString(text)
      .split(regex)
      .map((part, i) =>
        regex.test(part) ? (
          <span key={i} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        ) : (
          part
        )
      )
  }

  if (
    isLoadingOrdersHistory ||
    isLoadingOrderDoc ||
    isLoadingOrderDocPdf ||
    isLoadingOrderDocXlsx
  )
    return <Loader />

  if (isShowDetails) {
    return (
      <div className="overlayCont">
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div>Історія покупок</div>
            <div
              onClick={() => setIsShowOrdersHistory(false)}
              className={styles.closeIcon}
            >
              <ImCross />
            </div>
          </div>
          <div className={styles.toolBarContainer}>
            <span className={styles.showDetailsTextContainer}>Пошук:</span>
            <input
              type="text"
              placeholder=""
              value={detailsSearchTerm}
              onChange={(e) => setDetailsSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
            <span className={styles.showDetailsTextContainer}>Розгорнути</span>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={isShowDetails}
                onChange={handleShowDetailedHistory}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
          <hr className={styles.modalHrTop} />

          {currentDetailsItems.length === 0 ? (
            <div className={styles.noResults}>Пошук не дав результатів...</div>
          ) : (
            <>
              <table className={styles.historyOrderTable}>
                <thead>
                  <tr>
                    <th>№ Накладної</th>
                    <th>Звідки</th>
                    <th>Бренд</th>
                    <th>Номер</th>
                    <th>Опис</th>
                    <th>Ціна</th>
                    <th>Кількість</th>
                    <th>Сума</th>
                    <th>Дата</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDetailsItems?.map((order, i) => (
                    <tr key={i}>
                      <td>{highlightText(order.docID, detailsSearchTerm)}</td>
                      <td>
                        {highlightText(
                          order.isExternalOrder
                            ? 'Під замовлення'
                            : 'Наявність',
                          detailsSearchTerm
                        )}
                      </td>
                      <td>{highlightText(order.brand, detailsSearchTerm)}</td>
                      <td>
                        {highlightText(order.spareCode, detailsSearchTerm)}
                      </td>
                      <td>
                        {highlightText(order.description, detailsSearchTerm)}
                      </td>
                      <td>
                        {highlightText(
                          parseFloat(order.price).toFixed(2),
                          detailsSearchTerm
                        )}
                      </td>
                      <td>{highlightText(order.qty, detailsSearchTerm)}</td>
                      <td>
                        {highlightText(
                          (parseFloat(order.price) * order.qty).toFixed(2),
                          detailsSearchTerm
                        )}
                      </td>
                      <td>
                        {highlightText(
                          DateTime.fromJSDate(new Date(order.mvDate)).toFormat(
                            'dd.MM.yyyy'
                          ),
                          detailsSearchTerm
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr className={styles.modalHR} />
              <div className={styles.infoBtnContainer}>
                <button
                  disabled={detailsCurrentPage === 1}
                  onClick={goToPreviousDetailsPage}
                  className={styles.arrowBtn}
                >
                  <ImArrowLeft />
                </button>
                {detailsCurrentPage} з {totalDetailsPages}
                <button
                  disabled={detailsCurrentPage === totalDetailsPages}
                  onClick={goToNextDetailsPage}
                  className={styles.arrowBtn}
                >
                  <ImArrowRight />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  if (isShowDoc)
    return (
      <div className="overlayCont">
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div>Накладна № {docID}</div>
            <div
              onClick={() => setIsShowOrdersHistory(false)}
              className={styles.closeIcon}
            >
              <ImCross />
            </div>
          </div>
          <div className={styles.returnContainer}>
            <button onClick={handleReturn} className={styles.returnBtn}>
              Назад
            </button>
          </div>
          <table className={styles.historyOrderTable}>
            <thead>
              <tr>
                <th>Звідки</th>
                <th>Бренд</th>
                <th>Номер</th>
                <th>Опис</th>
                <th>Ціна</th>
                <th>Кількість</th>
                <th>Сума</th>
              </tr>
            </thead>
            <tbody>
              {docData?.orderDoc?.map((spare, i) => (
                <tr key={i}>
                  <td>
                    {spare.isExternalOrder ? 'Під замовлення' : 'Наявність'}
                  </td>
                  <td>{spare.brand}</td>
                  <td>{spare.spareCode}</td>
                  <td>{spare.description}</td>
                  <td>{parseFloat(spare.price).toFixed(2)}</td>
                  <td>{spare.qty}</td>
                  <td>{(parseFloat(spare.price) * spare.qty).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr className={styles.modalHR} />
          <div className={styles.docSumContainer}>Всього: {docSum}</div>
        </div>
      </div>
    )

  return (
    <div className="overlayCont">
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div>Історія покупок</div>
          <div
            onClick={() => setIsShowOrdersHistory(false)}
            className={styles.closeIcon}
          >
            <ImCross />
          </div>
        </div>
        {ordersHistory?.orderHistory.length === 0 ? (
          <div className={styles.noOrderHistory}>
            <p>Історія покупок відсутня...</p>
          </div>
        ) : (
          <>
            <div className={styles.toolBarContainer}>
              <span className={styles.showDetailsTextContainer}>Пошук:</span>
              <input
                type="text"
                placeholder=""
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
              <span className={styles.showDetailsTextContainer}>
                Розгорнути
              </span>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  checked={isShowDetails}
                  onChange={handleShowDetailedHistory}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <hr className={styles.modalHrTop} />

            {currentItems.length === 0 ? (
              <div className={styles.noResults}>
                Пошук не дав результатів...
              </div>
            ) : (
              <>
                <table className={styles.historyOrderTable}>
                  <thead>
                    <tr>
                      <th>№ Накладної</th>
                      <th>Сума</th>
                      <th>Переглянути</th>
                      <th>Експорт - pdf, xlsx</th>
                      <th>Дата відвантаження</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((order, i) => (
                      <tr key={i}>
                        <td>{highlightText(order.docID, searchTerm)}</td>
                        <td>{highlightText(order.sum, searchTerm)}</td>
                        <td>
                          <button
                            onClick={() =>
                              handleShowOrderDoc(order.docID, order.sum)
                            }
                            className={styles.viewBtn}
                          >
                            <FaEye />
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => handleExportPdf(order.docID)}
                            className={styles.exportPdfBtn}
                          >
                            <GrDocumentPdf />
                          </button>
                          <button
                            onClick={() => handleExportXlsx(order.docID)}
                            className={styles.exportExcelBtn}
                          >
                            <RiFileExcel2Line />
                          </button>
                        </td>
                        <td>{highlightText(order.sellDate, searchTerm)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr className={styles.modalHR} />
                <div className={styles.infoBtnContainer}>
                  <button
                    disabled={currentPage === 1}
                    onClick={goToPreviousPage}
                    className={styles.arrowBtn}
                  >
                    <ImArrowLeft />
                  </button>
                  {currentPage} з {totalPages}
                  <button
                    disabled={currentPage === totalPages}
                    onClick={goToNextPage}
                    className={styles.arrowBtn}
                  >
                    <ImArrowRight />
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ModalOrdersHistory

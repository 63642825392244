import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'
import registreSliceReducer from './registre/registreSlice'
import authSliceReducer from './auth/authSlice'
import searchSparesSliceReducer from './searchSpares/sparesSlice'

const preloadedState = {
  registreSlice: {
    isCodeSent: JSON.parse(localStorage.getItem('isCodeSent')),
    timeLeftForResend: JSON.parse(localStorage.getItem('timeLeftForResend')),
    fullTimeLeftForResend: JSON.parse(
      localStorage.getItem('fullTimeLeftForResend')
    ),
    isCodeVerified: JSON.parse(localStorage.getItem('isCodeVerified')),
    clientType: JSON.parse(localStorage.getItem('clientType')),
    isCodeSentResetPassword: JSON.parse(
      localStorage.getItem('isCodeSentResetPassword')
    ),
    isCodeVerifiedResetPassword: JSON.parse(
      localStorage.getItem('isCodeVerifiedResetPassword')
    ),
  },
  authSlice: {
    accessToken: localStorage.getItem('accessToken'),
  },
  sparesSlice: {
    hideIncomePrices: JSON.parse(localStorage.getItem('hideIncomePrices')),
    showReferencePrice: JSON.parse(localStorage.getItem('showReferencePrice')),
  },
}

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    registreSlice: registreSliceReducer,
    authSlice: authSliceReducer,
    sparesSlice: searchSparesSliceReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
})

export default store

import styles from './RegistrePage.module.css'
import FormSendVerificationCode from '../../components/registre/FormSendVerificationCode'
import { useSelector } from 'react-redux'
import {
  selectIsCodeSent,
  selectIsCodeVerified,
} from '../../redux/registre/registreSlice'
import FormPhoneVerification from '../../components/registre/FormPhoneVerification'
import FormRegistre from '../../components/registre/FormRegistre'

const RegistrePage = () => {
  const isCodeSent = useSelector(selectIsCodeSent)
  const isCodeVerified = useSelector(selectIsCodeVerified)

  return (
    <>
      <div className="page">
        <div className={styles.registrePageContainer}>
          <div>
            <h2 className={styles.companyName}>СПРИНТ</h2>
          </div>
          {isCodeVerified ? (
            <FormRegistre />
          ) : isCodeSent ? (
            <FormPhoneVerification />
          ) : (
            <FormSendVerificationCode />
          )}
        </div>
      </div>
    </>
  )
}

export default RegistrePage

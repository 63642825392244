import { apiSlice } from '../../api/apiSlice'

const apiAuth = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: `/client/login`,
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `/client/logout`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation } = apiAuth

import { apiSlice } from '../../api/apiSlice'

const apiClientData = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientData: builder.query({
      query: (body) => ({
        url: `/client/data`,
        method: 'get',
        body,
      }),
    }),
    getClientOrders: builder.query({
      query: () => '/client/orders',
    }),
    deleteZeroOrder: builder.mutation({
      query: (body) => ({
        url: '/client/orders/delete',
        method: 'DELETE',
        body,
      }),
    }),
    getOrderPermission: builder.mutation({
      query: () => ({
        url: '/client/orders/permission',
        method: 'GET',
      }),
    }),
    getClientPaymentPlan: builder.query({
      query: () => '/client/payment-plan',
    }),
    getClientPaymentHistory: builder.query({
      query: () => '/client/payment-history',
    }),
    getClientSearchHistory: builder.query({
      query: () => '/client/search-history',
    }),
    getClientOrderHistory: builder.query({
      query: () => '/client/orders/history',
    }),
    getClientOrderDoc: builder.mutation({
      query: (body) => ({
        url: '/client/orders/history/doc',
        method: 'POST',
        body,
      }),
    }),
    getClientOrderDocPdf: builder.mutation({
      query: (body) => ({
        url: `/client/orders/history/doc-pdf`,
        method: 'POST',
        body,
        responseHandler: async (response) => {
          if (!response.ok) {
            return { success: false }
          }
          const blob = await response.blob()
          const downloadUrl = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = `order_${body.docID}.pdf`
          document.body.appendChild(a)
          a.click()
          a.remove()
          return { success: true }
        },
      }),
    }),
    getClientOrderDocXlsx: builder.mutation({
      query: (body) => ({
        url: `/client/orders/history/doc-xlsx`,
        method: 'POST',
        body,
        responseHandler: async (response) => {
          if (!response.ok) {
            return { success: false }
          }
          const blob = await response.blob()
          const downloadUrl = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = `order_${body.docID}.xlsx`
          document.body.appendChild(a)
          a.click()
          a.remove()
          return { success: true }
        },
      }),
    }),
  }),
})

export const {
  useGetClientDataQuery,
  useGetClientOrdersQuery,
  useGetClientPaymentPlanQuery,
  useGetOrderPermissionMutation,
  useGetClientPaymentHistoryQuery,
  useGetClientSearchHistoryQuery,
  useGetClientOrderHistoryQuery,
  useGetClientOrderDocMutation,
  useGetClientOrderDocPdfMutation,
  useGetClientOrderDocXlsxMutation,
  useDeleteZeroOrderMutation,
} = apiClientData

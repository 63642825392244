import { useSelector } from 'react-redux'
import { selectClientType } from '../../redux/registre/registreSlice'
import FormRetailClient from './FormRetailClient'
import FormClientType from './FormClientType'
import FormWholesaleClient from './FormWholesaleClient'

const FormRegistre = () => {
  const clientType = useSelector(selectClientType)

  if (!clientType) {
    return <FormClientType />
  } else if (clientType === 1) {
    return <FormRetailClient />
  } else {
    return <FormWholesaleClient />
  }
}

export default FormRegistre

import { FaViber } from 'react-icons/fa'
import styles from './Viber.module.css'

const Viber = () => {
  return (
    <>
      <a href="viber://pa?chatURI=sprint_carparts">
        <FaViber className={styles.viberLogo} />
      </a>
      <div className={styles.phoneContainer}>
        <a className={styles.phoneLink} href="tel:+0800330049">
          Звʼязатись: 0800330049
        </a>
      </div>
    </>
  )
}

export default Viber

import filterString from './filterString'

const checkAvailabilityTable = (sparesArr) => {
  const resArr = sparesArr.filter((el) => el.availability > 0 || el.reserve > 0)
  if (resArr.length > 0) {
    return true
  } else {
    return false
  }
}

const checkSupplierSelectedAvailabilityTable = (
  sparesArr,
  searchInput,
  brand
) => {
  if (!brand) {
    return false
  }
  const resArr = sparesArr.filter(
    (el) =>
      !(el.availability > 0) &&
      !(el.reserve > 0) &&
      filterString(el.code) === searchInput &&
      filterString(el.brand) === filterString(brand)
  )

  if (resArr.length > 0) {
    return true
  } else {
    return false
  }
}
const checkSupplierAvailabilityTable = (sparesArr, searchInput) => {
  const resArr = sparesArr.filter(
    (el) =>
      !(el.availability > 0) &&
      !(el.reserve > 0) &&
      !(filterString(el.code) === searchInput)
  )
  if (resArr.length > 0) {
    return true
  } else {
    return false
  }
}

const checkAllTablesAvailability = (sparesArr, searchInput, brand) => {
  const availabilityTable = checkAvailabilityTable(sparesArr)
  const supplierSelectedAvailabilityTable =
    checkSupplierSelectedAvailabilityTable(sparesArr, searchInput, brand)
  const supplierAvailabilityTable = checkSupplierAvailabilityTable(
    sparesArr,
    searchInput
  )
  return (
    availabilityTable ||
    supplierSelectedAvailabilityTable ||
    supplierAvailabilityTable
  )
}

export {
  checkAvailabilityTable,
  checkSupplierSelectedAvailabilityTable,
  checkSupplierAvailabilityTable,
  checkAllTablesAvailability,
}

import { useSelector } from 'react-redux'
import {
  selectIsCodeSentResetPassword,
  selectIsCodeVerifiedResetPassword,
} from '../../redux/registre/registreSlice'
import styles from './ResetPasswordPage.module.css'
import SendVerificationCode from '../../components/resetPassword/SendVerificationCode'
import PhoneVerification from '../../components/resetPassword/PhoneVerification'
import FormNewPassword from '../../components/resetPassword/FormNewPassword'

const ResetPasswordPage = () => {
  const isCodeSent = useSelector(selectIsCodeSentResetPassword)
  const isCodeVerified = useSelector(selectIsCodeVerifiedResetPassword)

  return (
    <>
      <div className="page">
        <div className={styles.resetPasswordPageContainer}>
          <div>
            <h2 className={styles.companyName}>СПРИНТ</h2>
          </div>
          {isCodeVerified ? (
            <FormNewPassword />
          ) : isCodeSent ? (
            <PhoneVerification />
          ) : (
            <SendVerificationCode />
          )}
        </div>
      </div>
    </>
  )
}

export default ResetPasswordPage

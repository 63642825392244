import { ImCross } from 'react-icons/im'
import { PiWarningOctagonFill } from 'react-icons/pi'

import styles from './ModalSpareNotAvailable.module.css'

const ModalSpareNotAvailable = ({ setNoSparesAvailable }) => {
  return (
    <div className="overlayCont">
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div>Інформація</div>
          <div
            onClick={() => setNoSparesAvailable(false)}
            className={styles.closeIcon}
          >
            <ImCross />
          </div>
        </div>
        <div className={styles.noSparesContentContainer}>
          <PiWarningOctagonFill className={styles.noSparesIcon} />
          <div className={styles.noSparesTextContainer}>
            За вашим запитом запчастини відсутні...
          </div>
        </div>
        <hr className={styles.modalHR} />
        <div className={styles.infoBtnContainer}>
          <button
            onClick={() => setNoSparesAvailable(false)}
            className={styles.infoBtn}
          >
            ОК
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalSpareNotAvailable

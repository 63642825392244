import { ImCross } from 'react-icons/im'
import styles from './ModalDeliveryTimeDetails.module.css'

const ModalDeliveryTimeDetails = ({
  deliveryTimeDetails,
  setDeliveryTimeDetails,
}) => {
  return (
    <div className="overlayCont">
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <div>Інформація</div>
          <div
            onClick={() => setDeliveryTimeDetails(null)}
            className={styles.closeIcon}
          >
            <ImCross />
          </div>
        </div>
        <div className={styles.orderTimeContainer}>
          <div className={styles.infoText}>Час замовлення:</div>
          <div className={styles.orderTimeText}>
            {deliveryTimeDetails.supplierOrderInformation}
          </div>
        </div>
        <div className={styles.deliveryTimeContainer}>
          <div className={styles.infoText}>Час приходу:</div>
          <div className={styles.deliveryTimeText}>
            {deliveryTimeDetails.supplierDeliveryInformation}
          </div>
        </div>
        <hr className={styles.modalHR} />
        <div className={styles.infoBtnContainer}>
          <button
            onClick={() => setDeliveryTimeDetails(null)}
            className={styles.infoBtn}
          >
            ОК
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalDeliveryTimeDetails

import { useEffect } from 'react'
import { useGetClientSearchHistoryQuery } from '../../../redux/clientData/apiClientData'
import Loader from '../../loader/Loader'
import styles from './SearchHistory.module.css'

const SearchHistory = ({ rerender }) => {
  const {
    data: searchHistory,
    isLoading: isLoadingSearchHistory,
    refetch,
  } = useGetClientSearchHistoryQuery()

  useEffect(() => {
    refetch()
  }, [refetch, rerender])

  if (isLoadingSearchHistory) return <Loader />

  return (
    <div className={styles.searchHistoryPageContainer}>
      <div className={styles.searchHistoryContainer}>
        {searchHistory?.clientSearchHistory?.length > 0 ? (
          <table className={styles.searchHistoryTable}>
            <thead>
              <tr>
                <th>Бренд</th>
                <th>Номер</th>
                <th>Доступно</th>
                <th>Дата</th>
              </tr>
            </thead>
            <tbody>
              {searchHistory?.clientSearchHistory?.map((payment, i) => (
                <tr key={i}>
                  <td>{payment.brand}</td>
                  <td>{payment.code}</td>
                  <td>{payment.isSuccess ? 'Так' : 'Ні'}</td>
                  <td>{payment.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h3 className={styles.noPaymentPlanText}>
            За останній час історія пошуку відсутня
          </h3>
        )}
      </div>
    </div>
  )
}

export default SearchHistory

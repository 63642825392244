import { apiSlice } from '../../api/apiSlice'

const apiData = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyRates: builder.query({
      query: () => `/data/currency-rates`,
    }),
    getImageUrl: builder.mutation({
      query: (body) => ({
        url: '/data/image-url',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetCurrencyRatesQuery, useGetImageUrlMutation } = apiData

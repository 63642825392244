import { BsFillEyeSlashFill } from 'react-icons/bs'
import { FaEye } from 'react-icons/fa'
import styles from '../../pages/resetPassword/ResetPasswordPage.module.css'
import { useNavigate } from 'react-router-dom'
import { GrReturn } from 'react-icons/gr'
import { useState } from 'react'
import {
  useGetPhoneNumberQuery,
  usePasswordResetMutation,
} from '../../redux/registre/apiRegistre'
import { toast } from 'react-toastify'
import {
  setCodeSentResetPassword,
  setCodeVerifiedResetPassword,
} from '../../redux/registre/registreSlice'
import { useDispatch } from 'react-redux'
import Viber from '../viber/Viber'
import Loader from '../loader/Loader'

const FormNewPassword = () => {
  const [password, setPassword] = useState('')
  const [passwordSecond, setPasswordSecond] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordSecondVisible, setIsPasswordSecondVisible] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data: phoneNumberObj, isLoading: isGetPhoneNumberLoading } =
    useGetPhoneNumberQuery()

  const [changePassword, { isLoading: isChangePasswordLoading }] =
    usePasswordResetMutation()

  const handleReturnToPreviousPage = () => {
    dispatch(setCodeVerifiedResetPassword(false))
    dispatch(setCodeSentResetPassword(false))
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    if (password === passwordSecond) {
      try {
        const result = await changePassword({
          password,
          phoneNumber: phoneNumberObj.phoneNumber,
        }).unwrap()
        if (result?.changed) {
          dispatch(setCodeVerifiedResetPassword(false))
          dispatch(setCodeSentResetPassword(false))
          navigate('/login')
        } else {
          toast.error('Сталась помилка...')
        }
      } catch (error) {
        toast.error('Сталась помилка...')
      }
    } else {
      toast.error('Паролі не співпадають')
    }
  }
  return (
    <>
      {isGetPhoneNumberLoading && <Loader />}
      {isChangePasswordLoading && <Loader />}
      <form
        className={styles.resetPasswordContainer}
        onSubmit={handleSubmitForm}
      >
        <div className={styles.resetPasswordTextContainer}>
          <h2>Відновлення пароля</h2>
          <p className={styles.secondaryText}>Будь ласка, придумайте пароль.</p>
        </div>
        <div className={styles.resetPasswordForm}>
          <div
            onClick={handleReturnToPreviousPage}
            className={styles.overlayTextReturn}
          >
            <GrReturn />
            <h3>Назад</h3>
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelForm}>Пароль (від 6 символів):</label>
            {isPasswordVisible ? (
              <BsFillEyeSlashFill
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className={styles.eyeIcon}
              />
            ) : (
              <FaEye
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className={styles.eyeIcon}
              />
            )}
            <input
              minLength={6}
              maxLength={50}
              required
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelForm}>Пароль ще раз:</label>
            {isPasswordSecondVisible ? (
              <BsFillEyeSlashFill
                onClick={() =>
                  setIsPasswordSecondVisible(!isPasswordSecondVisible)
                }
                className={styles.eyeIcon}
              />
            ) : (
              <FaEye
                onClick={() =>
                  setIsPasswordSecondVisible(!isPasswordSecondVisible)
                }
                className={styles.eyeIcon}
              />
            )}
            <input
              minLength={6}
              maxLength={50}
              required
              type={isPasswordSecondVisible ? 'text' : 'password'}
              value={passwordSecond}
              onChange={(e) => setPasswordSecond(e.target.value)}
            />
          </div>
          <div className={styles.btnContainer}>
            <button className={styles.resetPasswordBtn} type="submit">
              Змінити пароль
            </button>
          </div>
        </div>
        <Viber />
      </form>
    </>
  )
}

export default FormNewPassword

import React, { useState } from 'react'
import Input from 'react-phone-number-input/input'
import { FaRegCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import styles from '../../pages/resetPassword/ResetPasswordPage.module.css'
import {
  useCheckPhoneExistanceMutation,
  useSendPhoneNumberMutation,
} from '../../redux/registre/apiRegistre'
import Loader from '../loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFullTimeLeftForResend,
  setCodeSentResetPassword,
  setFullTimeLeftForResend,
  setTimeLeftForResend,
} from '../../redux/registre/registreSlice'
import getCurrentTime from '../../utils/getCurrentTime'
import { toast } from 'react-toastify'
import Viber from '../viber/Viber'
import { GrReturn } from 'react-icons/gr'

const SendVerificationCode = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)

  const fullTimeLeftForResend = useSelector(selectFullTimeLeftForResend)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [checkPhoneExistance, { isLoading: isCheckPhoneExistanceLoading }] =
    useCheckPhoneExistanceMutation()
  const [sendPhoneNumber, { isLoading: isSendPhoneNumberLoading }] =
    useSendPhoneNumberMutation()

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e)
    setIsBtnDisabled(!(e?.length === 13))
  }

  const handleSubmitPhoneForm = async (e) => {
    e.preventDefault()
    const validPhoneNumber = phoneNumber.split('+')[1]
    try {
      const isPhoneExists = await checkPhoneExistance({
        phoneNumber: validPhoneNumber,
      }).unwrap()
      if (!isPhoneExists.phoneExist) {
        toast.error('Такий номер ще не зареєстрований')
        return
      }
    } catch (error) {
      toast.error('Сталась помилка...')
    }
    if (fullTimeLeftForResend <= new Date()) {
      try {
        const result = await sendPhoneNumber({ validPhoneNumber }).unwrap()
        if (result.codeSent) {
          dispatch(setCodeSentResetPassword(true))
          dispatch(setTimeLeftForResend(getCurrentTime(5)))
          dispatch(
            setFullTimeLeftForResend(
              new Date().setMinutes(new Date().getMinutes() + 4)
            )
          )
        }
      } catch (error) {
        toast.error('Сталась помилка...')
      }
    } else {
      toast.error(`Отримати новий код можна через 5хв`)
    }
  }

  const returnToPreviousPage = () => {
    navigate('/login')
  }

  return (
    <>
      {isSendPhoneNumberLoading && <Loader />}
      {isCheckPhoneExistanceLoading && <Loader />}
      <form
        className={styles.resetPasswordContainer}
        onSubmit={handleSubmitPhoneForm}
      >
        <div className={styles.resetPasswordTextContainer}>
          <h2>Відновлення пароля</h2>
          <p className={styles.secondaryText}>
            Будь ласка, внесіть свої дані в поля форми.
          </p>
        </div>
        <div className={styles.resetPasswordForm}>
          <div
            onClick={returnToPreviousPage}
            className={styles.overlayTextReturn}
          >
            <GrReturn />
            <h3>Назад</h3>
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.labelForm}>Номер телефону:</label>
            <Input
              country="UA"
              international
              withCountryCallingCode
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            {!isBtnDisabled && (
              <FaRegCheckCircle className={styles.correctLogo} />
            )}
          </div>

          <div className={styles.btnContainer}>
            <button
              disabled={isBtnDisabled}
              className={
                isBtnDisabled
                  ? styles.resetPasswordBtnDisabled
                  : styles.resetPasswordBtn
              }
              type="submit"
            >
              Отримати код
            </button>
          </div>
        </div>
        <Viber />
      </form>
    </>
  )
}

export default SendVerificationCode

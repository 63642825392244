import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchedSpare: null,
  searchedFinalSpare: {},
  hideIncomePrices: false,
  showReferencePrice: false,
}

const sparesSlice = createSlice({
  name: 'sparesSlice',
  initialState,
  reducers: {
    setSearchedSpare: (state, action) => {
      state.searchedSpare = action.payload
    },
    setSearchedFinalSpare: (state, action) => {
      state.searchedFinalSpare = action.payload
    },
    setHideIncomePrices: (state, action) => {
      state.hideIncomePrices = action.payload
      localStorage.setItem('hideIncomePrices', action.payload)
    },
    setShowReferencePrice: (state, action) => {
      state.showReferencePrice = action.payload
      localStorage.setItem('showReferencePrice', action.payload)
    },
  },
})

export const {
  setSearchedSpare,
  setSearchedFinalSpare,
  setHideIncomePrices,
  setShowReferencePrice,
} = sparesSlice.actions

export const selectSearchedFinalSpare = (state) =>
  state.sparesSlice.searchedFinalSpare
export const selectSearchedSpare = (state) => state.sparesSlice.searchedSpare
export const selectHideIncomePrices = (state) =>
  state.sparesSlice.hideIncomePrices
export const selectShowReferencePrice = (state) =>
  state.sparesSlice.showReferencePrice

export default sparesSlice.reducer

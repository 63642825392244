import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accessToken: null,
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
      localStorage.setItem('accessToken', action.payload)
    },
    setLogout: (state, action) => {
      state.accessToken = null
      localStorage.removeItem('accessToken')
    },
  },
})

export const { setAccessToken, setLogout } = authSlice.actions

export const selectAccessToken = (state) => state.authSlice.accessToken

export default authSlice.reducer

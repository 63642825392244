import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import LoginPage from './pages/login/LoginPage'
import RegistrePage from './pages/registre/RegistrePage'
import { ToastContainer } from 'react-toastify'
import RequireAuth from './layouts/RequireAuth'
import HomePage from './pages/home/HomePage'
import ResetPasswordPage from './pages/resetPassword/ResetPasswordPage'
import NotFoundPage from './pages/404/NotFoundPage'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <ToastContainer />
        <Routes>
          <Route path="/" element={<RequireAuth />}>
            <Route index element={<HomePage />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrePage />} />
          <Route path="/reset/password" element={<ResetPasswordPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
